import { INavData } from "@coreui/angular";

export let navItems1: INavData[] = [];

// Fonction pour charger les données depuis localStorage
function loadNavItems(): INavData[] {
  const menuData = JSON.parse(localStorage.getItem("menu"));
  console.log(" Tracker menuData", menuData)

  if (!menuData) {
    console.warn("Aucune donnée trouvée dans localStorage pour 'menu'.");
    return [];
  }

  try {
    // Example of splitting the menuData string (you can change the delimiter as per your data format)
    // Let's assume the data is something like: "name:url:icon|name:url:icon"
    const splitData = menuData.split(",");  // Assuming '|' is the delimiter for each item
    console.log(menuData);
   
    let parsedData: INavData[] = [];

    splitData.forEach((item) => {
      const parts = item.split(":");  // Split each item by the colon (':') for name, url, and icon
      if (parts.length === 3) {
        parsedData.push({
          name:parts[0],
          url:parts[1],
          icon:parts[2],
        });
      }
    });

    console.log(parsedData);
    return parsedData;

  } catch (error) {
    console.log("Erreur lors de l'analyse des données 'menu':", error);
  }

  return [];
}

// Charger les éléments de navigation
navItems1 = loadNavItems();

console.log("Mohamed",navItems1);  // The final result will be an array of INavData objects
